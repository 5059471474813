/* .sidebar{
    height: 100vh;
    width: 250px;
    background-color: #1D417A;
} */
/* .example::-webkit-scrollbar {
    display: none;
  } */
/* 

  /* dl, ol, ul{
    padding-left: 0 !important;
    padding-right: 0 !important;  
    margin-right: 0 !important;
  } */
/* 
.sidebarList{
    height: 100vh;
    padding: 0;
    width: 100%;
} */
/* .sidebarList .innerlist{
    width: 100%;
    height: 50px;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    font-family: 'Montserrat';
    color: rgba(249, 251, 253, 0.5);
    justify-content: center;
    align-items: center;
} */
/* .sidebarList .innerlist:hover{
    cursor: pointer;
    color: #FFFFFF;
    padding-left: -5% !important;
    background-color: rgba(249, 251, 253, 0.5);
}
.sidebarList #active{
    background-color: rgba(249, 251, 253, 0.5);  
} */
.input-icons{
    position: relative;
}
.input-icons i {
    position: absolute;
}

.input-icons {
    width: 100%;
    /* margin-bottom: 10px; */
}
.toggleicon{
    right: 10px;
    padding: 10px;
  
}

.innerlist .icon{
    flex: 30%;
    display: grid;
    place-items: center;
}
.innerlist .title{
    flex: 70%;
} 
.sidebarList{
    width: 100% !important;
}
.leftpanel{
    /* position: fixed; */
    background-color: #1D417A;
    height: 120vh;
}
.rightpanel{
    background-color: #F9FBFD;
    height: 100vh;
}

.lefttext{
    color: #F9FBFD;
    font-size: 47px;
    align-self: center;
    /* text-align: left; */
    align-items: center;
    justify-content: center;
    font-family:'Gill Sans MT';
    letter-spacing: 1px;
}

.audiencetext{
    font-size: 24PX;
    font-family:'Gill Sans MT','Regular';
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    color: #F9FBFD;
}

.emailtext{
    color: #000000;
    font-size: 24px;
    line-height: 26px;
    font-family:'Gill Sans MT','Regular';
    letter-spacing: 1px;
    /* font-weight: bold; */
}
input:focus { 
    outline: none !important;
    border-color:#373759 !important;
    /* box-shadow: 0 0 10px #719ECE; */
}

.emailinput{
    width: 100%;
    height: 44px;
    border: 1px solid #E2E2E2;
    background-color: #F9FBFD;
    border-radius: 9px;
    opacity: 1;
}

.emailinput::placeholder {
    color:rgba(0, 0, 0, 0.4) !important;
    padding: 10px;
    align-self: center;
    align-items: center;
    font-size: 18px;
    font-family: 'Montserrat';
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media (max-width: 768px){
    .lefttext{
    font-size: 24px;
    align-self: center;
    justify-content: center;
    }   
    .audiencetext{
        font-size: 18PX;
        align-items: center;
        justify-content: center;
     
    }
    .linkedintext{
      
        font-size: 14px;
       
    }
}

.errors{
    font-size: 14px;
    line-height: 16px;
    color: #ff0000;
    text-align: end;
    display: block;
  }

.forgotpassword{
    cursor: pointer;
    color: #000000;
    font-size: 16px;
    text-align: right;
    line-height: 18px;
    font-family:'Montserrat','Medium';
    letter-spacing: 1px;
}
.forgotpasswords{
    cursor: pointer;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    /* text-align: right; */
    line-height: 18px;
    font-family:'Montserrat';
    letter-spacing: 1px;
}

.loginbutton{
    width: 100%;
    height: 50px;
    background-color: #1D417A;
    border: none;
    border-radius: 9px;
    opacity: 1;
}
.loginbuttondis{
    width: 100%;
    height: 50px;
    background-color: rgba(29, 65, 122, 0.5);
    border: none;
    border-radius: 9px;
    opacity: 1;
}
.logintext{
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    align-items: center;
    text-align: center;
    align-self: center;
    padding-top: 10px;
    color: #FFFFFF;
    letter-spacing: 0.035em;
}
.ortext{
    color: #000000;
    font-size: 18px;
    align-self: center;
    font-family: 'Montserrat';
    font-weight: 'Semibold';
    opacity: 0.4;
}

.linkedinbutton{
background-color: #FFFFFF;
width: 100%;
height: 50px;
border: 1px solid #373759;
border-radius: 9px;
opacity: 1;
}

.linkedintext{
    font-family: 'Montserrat';
    font-weight: 'Medium';
    font-size: 18px;
    align-items: center;
    text-align: center;
    color: #000000;
    letter-spacing: 0.035em;  
}

  hr {
    border: 1px solid rgb(150, 150, 150);
  }
  
  .green{
      color: green;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 1px;
      font-weight: 600;
  }

  .red{
    color: red;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: 600;
}

.icon{
    size: 14px;
    align-self: center;
    justify-content: center;
    margin-right: 10px;
}

/* .css-yk16xz-control {
border-radius: 9px !important;
border-color: #373759 !important;
min-height: 50px !important;
}

.css-tj5bde-Svg{
    fill: black !important;
}



.css-1uccc91-singleValue {
    color: #000000 !important;
}

.css-1pahdxg-control{
    border-color: #373759 !important;
    box-shadow:none !important ;
    height: 53px !important;
    border-radius: 12px !important;
}
.css-1pahdxg-control:hover{
    outline: none !important;
    border-color:#373759 !important; 
}

.css-1okebmr-indicatorSeparator {
    width: none !important;
}

.css-1wa3eu0-placeholder {
    color: rgba(0, 0, 0, 0.4) !important;
} */
