.redzonebutton{
    margin-top: 2.4em;
    background-color: rgba(29, 65, 122, 1);
    border-radius: 4px;
    height: 30px;
    width: 50px; 
}

.redzonetext{
    font-size: 9px;
    align-self: center;
   /* font-family: 'M'; */
}


.multilinedata{
    outline: none !important;
    border-radius: 0;
    height: 65px;
}

.multilinedata::placeholder{
    font-size: 10px;
    padding-left: 5px;
    padding-top: 5px;
}
.textcomp{
    width: 100%;
}

.scrollredzone {
    /* margin: 4px, 4px; */
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
}