.campaignlabel{
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    line-height: 20px;
    font-family: 'Gill Sans';
    font-weight: bold;
    align-items: center;
    justify-content: center;
}
.campaigntext{
    background-color: #F9FBFD;
    padding: 15px;
    height: 47px;
    font-family: 'Montserrat';
    width:100%;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
}
.campaigntext::placeholder{
  font-family: 'Montserrat' !important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.035em !important;
  color:rgba(0, 0, 0, 1) !important;
}

.workstackmultiplenotes{
  background-color: #F9FBFD;
  padding: 20px;
  height: 30px;
  font-family: 'Montserrat';
  width:100%;
  border: 1px solid #E2E2E2;
  border-radius: 4px;
}

.workstackmultiplenotes::placeholder{
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.035em !important;
  color:rgba(0, 0, 0, 1) !important;
}


#input-example{
  height: 47px;
  width: 100%;
  border: 1px solid #E2E2E2 !important;
  border-radius: 12px !important;
  font-family: 'Montserrat' !important;
  outline: none !important;
  padding: 15px;
}
input:focus { 
    outline: none !important;
    border-color:rgba(226, 226, 226, 1);
    /* box-shadow: 0 0 10px #719ECE; */
}

    .container{
        padding-top: 5%;
        padding-left: 30% !important;
        padding-right: 30% !important;
    }

    .css-1okebmr-indicatorSeparator {
        width: 0 !important;
    }

    .comptext{
        color: #000000;
        font-size: 22px;
        font-weight: 700;
        font-family: 'Gill Sans';
    }

    .highlightOption{
    background-color: #1D417A !important;
    }

    

    .chip{
        background-color: #1D417A !important;
    }

    .searchWrapper{
    border-radius: 12px !important;
    min-width: -60px !important;
    
    border: 1px solid rgba(226, 226, 226, 1) !important;
    }

    .multiSelectContainer li:hover{
        background-color: #1D417A !important;
        border-radius: 12px !important;
    }

    .multiSelectContainer li{
        border-radius: 12px !important;
    }


    .optionContainer { 
        border: 2px solid;
        border-radius: 12px !important;
      }

      /*  for campaign title field */
    
      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
      border-radius: 12px !important;
      }
      .css-1v4ccyo{
        border-radius: 12px !important;
      }
      .multiSelectContainer input{
      padding-top: 5px !important;
      color: #000000
      }

      .arrow-closed{
        position: absolute;
        top: 25px;
        left: 25em;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 10px solid black;
      }

    .inputContainer i {
        position: absolute;
     }
     .inputContainer {
        width: 100%;
        margin-bottom: 10px;
     }
     .icon {
        color: rgb(49, 0, 128);
     }
   
     .fileContainer {
        background: #fff;
        position: relative;
        border-radius: 12px;
        border: 1px solid rgba(226, 226, 226, 1);
        padding: 15px;
        height: 47px;
        font-family: 'Montserrat';
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease-in;
      }
    .fileContainer input {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      .plusimg{
            margin-left: 25px !important;
            color: #000000;
            height: 20px;
            width: 20px;
            /* margin-top: 10px !important; */
            justify-content: center;
            align-self: center;
            border: 1px solid #707070;
            border-radius: 5px;
      }

      .uploads {
        font-size: 16px;
        line-height: 18px;
        align-items: center;
        align-self: center;
        font-family: 'Montserrat';
        color: #000000;
        margin-left: 12px;
    }

      
      .errors{
        font-size: 14px;
        line-height: 16px;
        color: #ff0000;
        text-align: end;
        display: block;
      }

     .react-datepicker-wrapper {
        width: 100%;
      }
     /* Campaignreview */
      .invoicesfor{
      font-size: 18px;
      font-family: 'Montserrat';
      color: #A3A3A3;
      text-align: left;
      align-items: center;
      align-self: center;
      }

      .invoicesfortext{
        font-size: 16px;
        line-height: 18px;
        font-family: 'Montserrat';
        color: #000000;
        text-align: left;
        align-items: center;
        align-self: center;  
      }
      .invoicesfortextlight{
        font-size: 14px;
        font-family: 'Montserrat';
        color: #000000;
        text-align: left;
        align-items: center;
        align-self: center;   
      }
      .invoicesborder{
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          width: 75%;
          
      }

      /* Toolsequencing */
      .tool{
        font-size: 24px;
        font-family: 'Gill Sans MT';
        color: rgba(29, 65, 122, 1);
        text-align: left;
        align-items: center;
        align-self: center;      
      }
      
      .lefttext1{
          margin-left: 2%;
      }
      .menublock {
        background: #1D417A;
        padding: 0 10px;
        height:  160vh;
      }

      .menublock1 {
        background: #1D417A;
        max-width: 388px !important;
        padding: 0 10px;
        height:  160vh;
      }
      .not{
        font-size: 19px;
        margin-left: 2em !important;
        font-family: 'Gill Sans MT';
        color: #000000;
      }
      .notblur{
        font-size: 14px;
        line-height: 16px;
        margin-left: 2.6em !important;
        font-family: 'Gill Sans MT';
        color: rgba(0, 0, 0, 0.4);
      }
      
      /* To customize tab bar style */
      .css-1aquho2-MuiTabs-indicator{
      background-color: rgba(55, 55, 89, 1) !important;
      }

      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: rgba(0, 0, 0, 0.4) !important;
        text-transform: capitalize;
        font-size: 14px;
        line-height: 16px;
        align-self: center;
      }


      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
        color: rgba(0, 0, 0, 1) !important;
        text-transform: capitalize !important;
        font-size: 14px !important;
        line-height: 16px !important;
        align-self: center;
      } 
 
       .css-1q2h7u5.Mui-selected{
        color: rgba(0, 0, 0, 1) !important;
        text-transform: capitalize !important;
        font-size: 14px !important;
        line-height: 16px !important;
        align-self: center;
      }
       .css-1q2h7u5{
        color: rgba(0, 0, 0, 1) !important;
        text-transform: capitalize !important;
        font-size: 14px !important;
        line-height: 16px !important;
        align-self: center;
      } 

      .css-ttwr4n {
        background-color: rgba(55, 55, 89, 1) !important;
      }

      .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        max-width: 130px !important;
        /* margin-right: 25em !important; */
      } 

      .savesetup{
        margin-right: 2em !important;
      }

      /* to change material ui radio button color */
      .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
      color: #1D417A !important;
      }
      #active{
        background-color: rgba(249, 251, 253, 0.2);
        color: #FFFFFF;  
    }
      .header {
        background:#F9FBFD
      }
      .maindiv{
        margin-left: 2% !important;
        margin-right: 1% !important;
      }

      .maindivproducer{
        margin-left: 1% !important;
        margin-right: 1% !important;
      }
    
      .maindiv1{
        margin-left: 2% !important;
        margin-right: 5% !important;
      }
      .maindiv3{
        margin-left: 2.2em !important;
        margin-right: 8em !important;
      }
      
      .maindiv2{
        margin-left: 1% !important;
        margin-right: 4% !important;
      }
      
      .alexa {
        white-space: nowrap;
        text-align: left;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: rgba(0,0,0,1);
      }

      /* <=====productivity styles =====> */
      .productivityheadings{
      margin-left: 2em !important;
      font-size: 16px;
      line-height: 18px;
      align-self: center;
      font-family: 'Gill Sans MT';
      color: #1D417A;
      }
      .productivitytext{
        margin-left: 2em !important;
        font-size: 16px;
        line-height: 18px;
        align-self: center;
        font-family: 'Gill Sans MT';
        color: rgba(0, 0, 0, 0.4);
      }
      .productivitytext1{
        margin-left: 2em !important;
        font-size: 16px;
        line-height: 18px;
        align-self: center;
        font-family: 'Gill Sans MT';
        color: #1D417A;
      }
      .productivitytext11{
        margin-left: 2em !important;
        font-size: 16px;
        line-height: 18px;
        align-self: center;
        font-family: 'Gill Sans MT',;
        color: #1D417A;
      }
    
   
      
    .mainblock {
    background-color: #EEF5F9;
    height: 200vh;
    }

    /* to customize title checkbox color */
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
      color: #FFFFFF !important;
    }

    
    
      
    
    .campaignsetup {
        white-space: nowrap;
        text-align: left;
        font-family: 'Gill Sans MT';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        color: rgba(29,65,122,1);
      }
    
    
      .campaignsetup12{
        text-align: left;
        font-family: 'Gill Sans MT';
        align-self: center;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 20px;
        font-size: 18px;
        color: #000000;
      }
      
      .createtext{
        font-family: 'Montserrat';
        font-size: 16px;
        line-height: 18px;
        align-items: center;
        align-content: center;
        align-self: center;
        text-align: center;
        color: #FFFFFF;
        letter-spacing: 0.035em;
    }
       .MuiCheckbox-colorSecondary.Mui-checked{
         color: #000000 !important;
       }
  
    .createbutton{
      width: auto;
      height: 50px;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      justify-content: center;
      background-color: #1D417A;
      border: none;
      border-radius: 9px;
      opacity: 1;
    }
    .createbuttonnext {
      width: auto;
      height: 20px;
      padding-left: 18px;
      padding-right: 18px;
      padding-top: 5px;
      padding-bottom: 30px;
      background-color: #1D417A;
      border: none;
      border-radius: 9px;
      opacity: 1;
  }
    
    .listcontainer{
      height: 100vh;
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
      /* margin-bottom: 200px; */
    }

    .listcontainercustomer{
      height: 100%;
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
    }

    .listcontainerhead{
      /* height: auto; */
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
    }
    .listcontainer1{
      height: 370px;
      padding-right: 0 !important;
      padding-left: 0 !important;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
    }
    .listcontainer2{
      padding-right: 0 !important;
      padding-left: 0 !important;
      height: 400px;
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
    }
    .userstatesconatainer{
      padding-right: 0 !important;
      padding-left: 0 !important;
      height: 400px;
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
    }
    .campaignnocontainer{
      width: 154px;
      align-self: center;
      justify-content: center;
      height: 113px;
      background-color: #FFFFFF;
      border-radius: 5px;
      box-shadow: 0px 1px 3px #00000029;
    }
    .totalcampnotext{
      font-size: 45px;
      font-family: 'Montserrat';
      color: #1D417A;
      padding: 1em;
      font-weight: 700;
    }
    .totalcampnotext1{
      font-size: 14px;
      font-family: 'Montserrat';
      color: #1D417A;
      padding: 1em;
    }
    /* line charts */
    .linecontainer1{
      height: 400px;
      margin-left: -10px;
      margin-right: 10px;
      width: 600px;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px #00000029;
    }
    .linecontainer2{
      height: 400px;
      width: 600px;
      /* padding: 15px; */
      margin-right: -10px;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
    }
    .linecontainer12{
      height: 400px;
      width: 600px;
      /* margin-left: 5em; */
      /* padding: 15px; */
      margin-right: -65px;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
    }
    /* <---- ----> */
    .strokelinetext{
      font-family: 'Gill Sans MT';
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.035em;
      padding-left: 1em;
      text-align: center;
      color: rgba(0, 0, 0, 1);
    }
    .strokelinetext1{
      font-family: 'Gill Sans MT';
      font-size: 19px;
      padding-left: 1em;
      padding-bottom: 1em;
      text-align: center;
      padding-bottom: 3em;
      color: rgba(0, 0, 0, 1);
    }
    .strokelinetext2{
      font-family: 'Gill Sans MT';
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.035em;
      padding-left: 1em;
      padding-right: 1em;
      color: rgba(0, 0, 0, 1);
    }
    .strokelinetext3{
      font-family: 'Gill Sans MT';
      font-size: 11px;
      padding-left: 1em;
      text-align: center;
      padding-bottom: 3em;
      color: rgba(0, 0, 0, 0.4);
    }
   .filterdiv{
     margin-right: 4% !important;
   }
    .datalistemail{
     margin-left: 49em;
    }
    .bdrbottom{
      border-bottom: 1px solid #D3D3D3;
    }
    
    .listheader{
      font-family: 'Gill Sans MT';
      font-size: 20px;
      line-height: 22px;
      align-self: center;
      align-items: center;
      text-align: left;
      color: rgba(29,65,122,1);
    }
    
    .listtext{
      text-align: left;
      font-family: 'Montserrat';
      font-size: 16px;
      line-height: 18px;
      align-self: center;
      align-items: center;
      color: rgba(0,0,0,1);
    }
    .hi{
      margin-right: -20px !important;
    }
    
    
      .leftnavtext{
      font-size: 16px;
      align-self: center;
      justify-content: center;
      }
    
      @media (max-width: 768px) {
        .leftnavtext {
          font-size: 12px;
        }
      }
    
    /* to apply style on manager status dropdown */
     .myClassName .Dropdown-control {
        border-radius: 9px !important;
        height: 30px !important;
        color: #000000 !important;
    }
    
    .Dropdown-menu {
      border-radius: 12px !important;
    }
    .Dropdown-menu .Dropdown-option:hover {
      background-color: #1D417A !important;
      border-radius: 12px !important;
      color:#ffffff;
    }
    .Dropdown-placeholder .Dropdown-placeholder {
      color: #000000 !important;
    }
    .Dropdown-arrow{
      border-color: #000000 transparent transparent !important;
    }
    
    .is-open .Dropdown-arrow {
      border-color: transparent transparent #000000 !important;
      text-align: center !important;
      /* padding-top: 10px !important; */
      border-width: 0 5px 5px;
    }
    .Dropdown-control {
      padding: 8px 52px 30px 10px !important;
      background-color: none !important;
      box-shadow: 0px 3px 6px #00000029;
      border: none !important;
    }
    /* <----- end here ----> */
    
    
    /* button, input, optgroup, select, textarea{
    height: 47px;
    width: 100%;
    border-radius: 12px;
    align-self: center;
    border: 1px solid #E2E2E2;
    padding-bottom: 10px;
    }
    */


    .table>:not(:last-child)>:last-child>* {
      border-bottom-color: #E2E2E2 !important;
    }

    .table-fixed{
      width: 100%;
      background-color: #f3f3f3;
    }
 

     table > * > tr > th,
     table > * > tr > td {
      padding-top: 2em !important;
      padding-bottom: 2em !important;
      }
      
      .table>:not(caption)>*>*{
        border-bottom: 0 !important;
      }
      .table>:not(:last-child)>:last-child>*{
        border-bottom-color: #ffffff !important;
      }

      .table > tbody > tr:nth-of-type(odd){background-color: #F6F6F6 !important;    }

      .table > tbody > tr.Colorred{
        background-color: #EF616B !important
      }

      div.scroll {
        /* margin: 4px, 4px; */
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        width: 100%;
        overflow-x: auto;
        overflow-y: auto;
        white-space: nowrap;
    }
   
/* toolsequencing multiselect */
.css-9gakcf-option {
  background-color: #1D417A !important;
}

.css-12jo7m5{
  font-size: 12px !important;
  font-family: 'Montserrat' !important;
}

/* to customize material ui dropdown */
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: none !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color: #E2E2E2 !important;
  /* background-color: #F9FBFD !important; */
}
.css-igs3ac{
  border-color: #E2E2E2 !important;
  /* background-color: #F9FBFD !important; */
}

.css-1a1ihhj {
    background-color: #F9FBFD !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
height: 0 !important;
min-height: 0 !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding-left:  14px !important;
  padding-right: 14px !important;
  padding-top: 0.5em !important;
  padding-bottom: 2em !important;
  font-family: 'Montserrat' !important;
  background: #F9FBFD !important;
 justify-content: center;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  font-family: 'Montserrat' !important;
  background: #F9FBFD !important;
}
.css-qiwgdb.MuiSelect-select {
height: 0 !important;
font-family: 'Montserrat' !important;
min-height: 0 !important;
}
.css-qiwgdb{
  padding-left:  14px !important;
  padding-right: 14px !important;
  padding-top: 0.5em !important;
  padding-bottom: 2em !important;
  background: #F9FBFD !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover{
  background-color: #1D417A !important;
  color: #FFFFFF !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
  background-color: #1D417A !important;
  color: #FFFFFF !important;
}
.css-9gakcf-option:hover{
  background-color: #1D417A !important;
}

.css-1fhf3k1-control {
  opacity: 0 !important;
}
.react-datepicker-wrapper .react-datepicker__input-container input{
  height: 47px;
  width: 100%;
  border-radius: 12px !important;
  padding: 15px !important;
  font-family: 'Montserrat' !important;
  background-color: #F9FBFD;
  border: 1px solid #E2E2E2;
}

/* confirm alert style */
.react-confirm-alert-button-group > button {
  background: #1D417A !important;
  width: auto;
  height: 30px;
}

.createbuttonsetup {
  width: auto;
  height: 39px;
  padding-left: 55px;
  padding-right: 55px;
  text-align: center;
  background-color: #1D417A;
  border: none;
  border-radius: 9px;
  opacity: 1;
}

.react-confirm-alert-body>h1{
font-size: 16px !important;
font-family: 'Montserrat' !important;
}

.react-confirm-alert-body{
  font-size: 14px !important;
font-family: 'Montserrat' !important;
}



/* to increase tool seq dropdown menu with  */
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
  width: 100% !important;
} 

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  width: 100% !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover{
  width: 100% !important;
}


.css-1vr111p-option:hover{
  background-color: #1D417A !important;
  color: #FFFFFF !important;
}
.css-1vr111p-option {
  color: #000000 !important;
  font-family: 'Montserrat' !important;
}

.css-1qprcsu-option{
  background-color: #1D417A !important;
  color: #FFFFFF !important;
  font-family: 'Montserrat' !important;
}

.css-1v99tuv{
  font-family: 'Montserrat' !important;
}





/* Tooltip background color */
.css-n5bisb-MuiTooltip-popper .MuiTooltip-tooltip{
  background-color: #1D417A !important;
}

.userlist-button{
  border: 1px solid #E2E2E2;
}

.btn {
  border: 1px solid #E2E2E2 !important;
  background-color: #F9FBFD !important;
  font-family: 'Montserrat';
  border-radius: 5px !important;
  padding: 2px 10px !important
}

.up-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-bottom: solid 7px #23857A;
  border-top-width: 0;
  cursor: pointer;
}

.down-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-top: solid 7px #23857A;
  border-bottom-width: 0;
  margin-top:5px;
  cursor: pointer;
}

.position-relative{
  position: relative;
}

.position-absolute{
  position: absolute;
  top: 50%;
  margin-left: 80px;
  transform: translateY(-50%);
}

/*<===== stepper css =====> */
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color: #23857A !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
color:#3f51b5 !important
}

.css-qivjh0-MuiStepLabel-label{
  font-family: 'Montserrat' !important;
  font-weight: bold !important;
}

.css-farnub{
  font-family: 'Montserrat' !important;
  font-weight: bold !important;
}

.css-1hngkjo{
  color : rgba(0, 0, 0, 0.4) !important
}

.css-farnub.Mui-active{
  font-family: 'Montserrat' !important;
  color : rgba(0, 0, 0, 0.4) !important
}