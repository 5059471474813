 .opencampaignheading{
    font-size: 24px;
    line-height: 26px;
    font-family: 'Gill Sans MT';
    font-weight: bold;
    align-items: center;
    align-self: center;
    color: #1D417A;
    text-align: left;
}
.opencampaignheader{
    font-family: 'Gill Sans MT';
    font-size: 18px;
    line-height: 20px;
    align-self: center;
    align-items: center;
    text-align: left;
    color: rgba(29,65,122,1);
}
.opencampaigntext{
    font-family: 'montserrate';
    font-size: 16px;
    line-height: 18px;
    align-self: center;
    align-items: center;
    text-align: left;
    color: rgba(0,0,0,1);   
}

.opencampaignheader102{
  font-family: 'Gill Sans MT';
  font-size: 18px;
  line-height: 20px;
  align-self: center;
  align-items: center;
  text-align: left;
  color: rgba(29,65,122,1);
}

.opencampaigntext102{
  font-family: 'montserrate';
  font-size: 16px;
  line-height: 18px;
  align-self: center;
  align-items: center;
  text-align: left;
  color: rgba(0,0,0,1);   
}

/* 
.opencampaigntext102:nth-of-type(odd){
  background-color: red
} */

.rowdata:nth-child(odd){
  background: #F6F6F6;
  padding: 0 !important;

}
  
/* for listing ui */
.scroller {
    height: 500px !important;
    /* padding-top: 15px; */
    /* padding-bottom: 15px; */
    /* padding-right: 15px; */
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    overflow-x: auto !important;
    overflow-y: auto !important;
    white-space: nowrap !important;
}
table{
    position: relative;
}
th {
    background-color: #FFFFFF !important;
    font-weight: normal;
    font-family: 'Gill Sans MT';
    z-index: 1 !important;
    background:#eee;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
  }
  /* td{
    align-self: center;
    justify-content: center;
  } */
  .table>:not(caption)>*>*{
    padding: 1.5rem 5.5rem !important;
  }
  
/* to apply style popup input button */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 0.0121em !important;
  }
  .css-1x5jdmq {
    height: 0.4375em !important; 
  }
 


  .notes{
  align-self: center !important;
  padding-top: 3em !important;
  }

  .addnotemanager{
     background-color: rgba(29,65,122,1); 
     border-radius: 4px;
     width: 50%;
     height: 30px;
    align-self: center;
     text-align: center;
  }
  .createtextnotes123{
    color: #FFFFFF;
    font-size: 11px;
    line-height: 12px;
  }

  .createtextnoteshead{
    color: #000000;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Montserrat';
  }

  .react-responsive-modal-closeButton  svg{
      display: none !important;
  }

  .notesdata{
      font-size: 11px;
      line-height: 12px;
      align-self: center;
      color: rgba(0,0,0,0.4);
  }

   .react-responsive-modal-modal{
       padding-left: 0 !important;
       padding-right: 0 !important;
       overflow-y: hidden !important;
       overflow-x: hidden !important;
  }

  .notescontainer{
    /* padding-top: 0 !important; */
      padding-left: 2em !important;
      padding-right: 2em !important;
  }
  
  /* to apply style to react toast */
  .Toastify__toast-body{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;
    font-family: 'Montserrat';
    color: #F9FBFD;
    align-items: center;
    align-self: center;
  }
  .Toastify__close-button > svg {
    fill: currentColor;
    height: 16px;
    width: 14px;
    margin-left: 6em;
    margin-bottom: 1em;
}

  /* to style status dropdown */
  .statusdrop {
    height: 30px;
    width: 130px;
    font-family: 'Montserrat';
    border-radius: 5px;
    border-color: #E2E2E2;
    background-color: #F9FBFD;
    /* background-color: #E6E6EA; */
}