.DatashaperinputfileContainer {
    background: #F9FBFD;
    position: relative;
    border-radius: 12px;
    border: 1px solid #E2E2E2;
    font-family: 'Montserrat';
    padding: 15px;
    height: 47px;
    display: flex;
    margin-right: 2em !important;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }
.DatashaperinputfileContainer input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  
  .DatashaperoutputfileContainer{
    background: #F9FBFD;
    position: relative;
    border: 1px dashed rgba(112, 112, 112, 1);
    padding: 15px;
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }


  .DatashaperoutputfileContainer input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .inputfileplaceholder{
    font-size: 14px;
    line-height: 16px;
    font-family: 'Montserrat';
    align-self: center;
    align-items: center;
    letter-spacing: 0.035em;
    color: rgba(0, 0, 0, 1);
  }

  .inputlabel{
      font-size: 16px;
      line-height: 18px;
      font-family: 'Gill Sans MT';
      align-self: center;
      align-items: center;
      letter-spacing: 0.035em;
      color: rgba(0, 0, 0, 0.4);
  }
  /* browse button style */
  .browsebutton {
    width: auto;
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    background-color: #1D417A;
    border: none;
    border-radius: 5px;
  }

  .browsebutton1 {
    width: auto;
    height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    justify-content: center;
    background-color: #1D417A;
    border: none;
    border-radius: 5px;
  }

  .browsebuttontext{
    font-family: 'Montserrat';
    font-size: 14px;
    align-items: center;
    align-content: center;
    align-self: center;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 0.045em;
}
.addmapvalue{
  font-size: 16px;
  line-height: 18px;
  font-family: 'Gill Sans MT';
  align-self: center;
  align-items: center;
  letter-spacing: 0.035em;
  color: #1D417A;
}

.outputhead{
    font-size: 16px;
      line-height: 18px;
      font-family: 'Gill Sans MT';
      align-self: center;
      align-items: center;
      letter-spacing: 0.035em;
      color: rgba(0, 0, 0, 1);
}

.progresslistcontainer{
    height: 625px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    padding: 5em 2em !important;
  }

.progresscontainer{
border: 1px solid rgba(233, 233, 233, 1);
border-radius: 10px;
opacity: 1;
height: 425px;
padding: 5em 2em !important;
}

.progressinnercontainer{
height: 247px;
box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.16);
opacity: 1;
padding-left: 3em !important;
padding-right: 3em !important;
padding-top: 2em !important;
}

.progressrange{
    font-size: 14px;
    font-family: 'Gill Sans MT';
    color: rgba(29, 65, 122, 1);
    align-self: center;
    align-items: center;
    text-align: center;
    margin-left: 15px;
    padding-left: 15px;
}

.progressrange1{
    font-size: 14px;
    font-family: 'Gill Sans MT';
    color: rgba(29, 65, 122, 1);
    align-self: center;
    align-items: center;
    
    /* text-align: center; */
}
/* to change pagination circle color */
.css-1hxrwmy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover{
background-color: #23857A !important;
}

.css-1hxrwmy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: #23857A !important;
  }

  .css-1vftd4z.Mui-selected{
    background-color: #23857A !important;
  }
  .css-1vftd4z.Mui-selected:hover{
    background-color: #23857A !important;
  }
  /* netline page button css */
  .netlinebutton{
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    border: none;
    padding-bottom: 5px;
    height: 30px;
    background-color: #23857A;
}

.netlinebuttontext{
    color: #FFFFFF;
    align-self: center;
    align-items: center;
    font-size: 16PX;
    line-height: 18PX;
    font-family: 'Montserrat';
}

.netlinecontainer{
  height: 65px; 
  padding: 15px;
  background-color: #FFFFFF;
  border: 1px solid #F1F1F1;
}

.netlinedate{
  font-size: 14px;
  /* padding-top: 25px; */
  margin-left: 1em !important;
  margin-right: 1em !important;
  letter-spacing: 0.025em;
  font-family: 'Montserrat';
  line-height: 16px;
  align-self: center;
}
/* netline dashboard filter date */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  height: 0.0025em !important;
}
.css-1uvydh2{
  height: 0.0025em !important;
}

.datemt .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  opacity: 0.4;
}

.statusdropnet{
  height: 30px;
  width: 60%;
  border-radius: 5px;
  font-family: 'Montserrat';
  border-color: #E2E2E2;
  background-color: #F9FBFD;
}
/* .statusdropnet:hover{
  background-color: red !important; 
} */
.netlineoption{
  font-family: 'Montserrat';
  /* background-color: blue; */
}
.netlineoption{
  font-family: 'Montserrat';
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
  font-family: 'Montserrat';
  color: #000000;
}
.netlineyes{
  font-size: 14px;
  font-family: 'Montserrat';
  color: #000000;
  line-height: 16px;
}
.groupnetlinebutton{
  margin-left: 5px;
}

.css-9l3uo3{
  font-family: 'Montserrat' !important;
  margin-left: 5px !important;
}
/* netline popup */
.react-responsive-modal-modal{
background-color: transparent !important;
box-shadow: none !important;
} 

/* creatable dropdown */

.css-yk16xz-control{
  height: 47px;
  border-radius: 9px !important;
  border-color: #E2E2E2 !important;
  background-color: #F9FBFD !important;
}

.css-1pahdxg-control{
  height: 47px;
  border-radius: 9px !important;
  border-color: #E2E2E2 !important;
  background-color: #F9FBFD !important;
  box-shadow: 0 0 0 0 #E2E2E2 !important;
}



.css-1wa3eu0-placeholder{
  font-family: 'Montserrat' !important;
  color: #000000 !important;
}

/* to apply right margin in add more button */
.savesetup{
  margin-right: 2em !important;
}
