.parent {
    position: relative;
    top: 0;
    left: 0;
  }

  .image1 {
    position: relative;
    top: 0;
    left: 0;
  }
  .image2 {
    position: absolute;
    margin-bottom: 13px;
    padding-right: 1px;
  }


  .alexa {
    white-space: nowrap;
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
  }

  .admin_user {
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0, 0.43);
  }

  .notificationtext{
    font-size: 16px;
    font-family: 'Montserrat';
    letter-spacing: 0.025em;
    line-height: 18px;
    align-self: center;
    align-items: center;
    color: rgba(0,0,0,0.4);
  }

  .profilecontainer{
    padding: 25px;
  }

  .profilelabel{
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.035em;
    font-family: 'Gill Sans MT';
    color: rgba(0,0,0,0.4);
  }

  
  /* .fileContainers {
    background: #fff;
    position: relative;
    border-radius: 50%;
    border: 1px solid rgba(226, 226, 226, 1);
    height: 110px;
    width: 110;
    font-family: 'Montserrat';
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in;
    box-shadow: rgb(221 221 221) 0px 2px 2px;
  } */
.fileContainers input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }


  


  .profilebutton {
    width: auto;
    height: 20px;
    padding-left: 18px;
    padding-right: 18px;
    /* padding-top: 9px; */
    padding-bottom: 32px;
    background-color: #1D417A;
    border: none;
    border-radius: 9px;
    opacity: 1;
}
  

.profiletextbutton{
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 26px; 
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  letter-spacing: 0.035em;
}

.profiletext{
  width: 100%;
  height: 47px;
  padding-left: 15px;
  border-radius: 12px;
  border: 1px solid rgba(226, 226, 226, 1);
  font-size: 14px;
 
}

.profiletext::placeholder{
  color: #000000 !important;
  font-family: 'Montserrat';
  background-color: #F9FBFD;
}
