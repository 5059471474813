.hotcampaignheader{
    font-family: 'Gill Sans MT';
    font-size: 18px;
    line-height: 20px;
    align-self: center;
    align-items: center;
    text-align: left;
    color: rgba(0, 0, 0, 0.4);
}

.hotcampgbutton{
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 10px;
    border-radius: 5px;
    height: 30px;
    background-color: rgba(29, 65, 122, 1);
}

.hotcampgtext{
    color: #FFFFFF;
    align-self: center;
    align-items: center;
    font-size: 16PX;
    line-height: 18PX;
    font-family: 'Montserrat',Montserrat;
}

