
 .menublock {
  background: #0072B1;
  max-width: 16.66666%;
  padding: 0 15px;
  /* height: 279vh; */
}

.small {
  max-width: 5.55555%;
  /* height: 279vh; */
}

.header {
  background: #ffffff;
  /* align-items: center;
   justify-content: center; */
}

.topsection {
  height: 70px;
}

.topsectionbrdr {
  border-bottom: 4px solid #EEF5F9;
}

.bottomsection {
  height: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
}

.current {
  color: #004B7C;
  font-size: 16px;
  margin: 0;
  line-height: 50px;
}

.pagetitle {
  text-transform: uppercase;
  font-family: 'NunitoBold';
  font-size: 22px;
  color: #004B7C;
  letter-spacing: 0.035em;
  line-height: 50px;
  margin: 0;
}

.bodycontent {
  padding: 30px 15px;
  /* background-color: #EEF5F9; */
}

.mainblock {
  background-color: #EEF5F9;
  min-height: 100vh;
}

.nav>li {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 18px 26px;
  width: 100%;
  color: #ffffff;
  opacity: 0.4;
  display: flex;
  cursor: pointer;
  /* font-size: 16px; */
  letter-spacing: 0.035em;
  /* line-height: 20px; */
}

.nav>li:hover {
  background-color: rgba(249, 251, 253, 0.25);
  opacity: 1 !important;
}


#selectednav{
  background-color: rgba(249, 251, 253, 0.25);
  opacity: 1;
}
#selectednav90{
  background-color: none;
  opacity: 1;
}

.navarrow:after, .nav>li.navarrow:hover::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 41.5%;
  right: 12.5%;
  border: solid rgba(255, 255, 255, 0.6);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-45deg);
  /* border-top: 0 solid transparent;
  border-right: 10px solid #EEF5F9;
  border-bottom: 10px solid transparent */
}

.nav li ul {
  display: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.nav li:hover ul {
  display: block;
  top: 0;
  position: absolute;
  right: -225px;
  z-index: 1;
  right: -1;
  width: 225px;
  background: rgba(29, 65, 122, 0.8);
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.nav li:hover ul li {
  padding: 18px 20px;
  border-bottom: 1px solid #EDEDED;
  border-left: 1px solid #EDEDED;
}

.nav li:hover ul li:first-child {
  border-top-right-radius: 5px;
}

.nav li:hover ul li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: none;
}

.dd-button:after {
  content: '';
  margin-left: 10px;
  margin-top: 12px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #004b7c;
}

.dd-button:hover {
  background-color: #eeeeee;
}

.profile:hover {
  background: #ffffff;
}

.top {
  color: '#ffffff';
  text-align: 'center';
  font-size: '18px';
  line-height: '20px';
  font-weight: 'bold';
  margin-top: '20px';
  margin-bottom: '20px';
  padding: 0;
}

@media (max-width: 768px) {
  .bodycontent {
    padding: 30px 0;
  }

  .pagetitle {
    font-size: 14px;
    letter-spacing: 0.035em;
  }

  .current {
    font-size: 12px;
  }

  .menublock {
    position: absolute !important;
    height: 160vh;
    z-index: 999;
  }

  .nav li:hover ul {
    right: -144px;
    width: 144px;
  }

  .nav li:hover ul li {
    padding: 10px 5px;
  }
}


.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #F9FBFD !important;
  border: 1px solid #E2E2E2 !important;
  border-radius: 12px !important;
  line-height: 25px;
  height: 47px !important;
  width: 100% !important;
  outline: none;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background: #F9FBFD !important;
  border: 1px solid #E2E2E2 !important;
  border-radius: 12px 0 0 12px !important;
}

.react-tel-input .selected-flag{
  /* background: #F9FBFD !important; */
  /* border: 1px solid #E2E2E2 !important; */
  border-radius: 12px 0 0 12px !important;
}